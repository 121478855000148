import { t } from "i18n-js";
import { useIsFormsEnabled } from "@/react/hooks/useIsFormsEnabled";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { useIsMarketingHubBillingEnabled } from "@circle-react/hooks/useIsMarketingHubBillingEnabled";
import { MarketingHubFreePeriodBanner } from "@circle-react-shared/MarketingHubFreePeriodBanner";
import { NavGroup } from "../NavGroup";
import { FooterWrapper } from "./FooterWrapper";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

const i18nRoot = "settings_nav_v3.emails";

export const EmailLinks = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const isFormsEnabled = useIsFormsEnabled();
  const isMarketingHubBillingEnabled = useIsMarketingHubBillingEnabled();

  const links: Link[] = [
    {
      label: t([i18nRoot, "broadcasts"]),
      path: settingsNavPath.email.broadcast,
      exact: true,
      isDisabled: !isCommunityAdmin(currentCommunityMember),
    },
    {
      label: t([i18nRoot, "forms"]),
      path: settingsNavPath.email.forms,
      exact: true,
      isDisabled: !isFormsEnabled || !isCommunityAdmin(currentCommunityMember),
    },
    {
      label: t([i18nRoot, "settings"]),
      path: settingsNavPath.email.settings,
      isDisabled: !isCommunityAdmin(currentCommunityMember),
      exact: true,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t([i18nRoot, "title"])}>
        {links.map(link => (
          <SubNavLinkItem
            key={link.path}
            label={link.label}
            path={link.path}
            isDisabled={link.isDisabled}
          />
        ))}
        {isMarketingHubBillingEnabled && (
          <FooterWrapper>
            <MarketingHubFreePeriodBanner />
          </FooterWrapper>
        )}
      </NavGroup.Content>
    </NavGroup>
  );
};
