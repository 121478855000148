import { t } from "i18n-js";
import { hasInstallmentPricing } from "@circle-react/helpers/communityMemberSubscriptionHelpers.js";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";

const RenewalDateText = ({ subscription }) => {
  const text =
    hasInstallmentPricing(subscription) &&
    subscription.next_billing_date == subscription.ends_at
      ? t(
          "settings.paywalls_admin_dashboard.subscriptions.subscription_details_modal.all_payments_completed",
        )
      : subscription.renews_on || subscription.next_billing_date
      ? formattedDateTime({
          dateTime: new Date(
            subscription.renews_on || subscription.next_billing_date,
          ),
          format: "long_date",
        })
      : " - ";
  return <span>{text}</span>;
};
export default RenewalDateText;
